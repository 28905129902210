// Components
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// Styles
import styles from "./styles.module.scss";
import classNames from "classnames";

const NavBar = () => {
  return (
    <Navbar bg="transparent" expand="lg" sticky="top">
      <Container fluid>
        <Navbar.Brand href="/">
          <span className={styles.brandText}>Mitchum AF</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={classNames("me-auto")}>
            <Nav.Link className={styles.navLink} href="/">
              Home
            </Nav.Link>
            <Nav.Link className={styles.navLink} href="/receiveHelp">
              Receive help
            </Nav.Link>
            <Nav.Link className={styles.navLink} href="/giveHelp">
              Help our cause
            </Nav.Link>
            <Nav.Link className={styles.navLink} href="/whoWeAre">
              Who we are
            </Nav.Link>
            <Nav.Link className={styles.navLink} href="/dedication">
              Dedication page
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
