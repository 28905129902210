import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// Styles
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <footer className={styles.footer}>
          <ul className={styles.footerLinkList}>
            <li>
              <Link to="/legal" className={styles.link}>
                Legal Documents
              </Link>
              <Link to="/contact" className={styles.link}>
                Contact Us
              </Link>
            </li>
          </ul>
        </footer>
      </Col>
    </Row>
  );
};

export default Footer;
