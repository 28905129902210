import LeighImage from '../assets/leigh.png'
import StaceyImage from '../assets/stacey.png'
import MindiImage from '../assets/mindi.png'
import KevinImage from '../assets/kevin.png'
import AngieImage from '../assets/angie.png'
import JeffreyImage from '../assets/jeffrey.png'

export const memberDetails = [
  {
    name: 'Leigh Treat',
    title: 'President',
    image: LeighImage,
    bio: `I have worked for the Hays County Sheriff's Office since 2011, and in 2021 it proved to be one of the most difficult for our family. We lost 3 others to illnesses before we lost Greg; his wish was to come up with a way to help others during extreme unforeseen instances. There were multiple fundraisers for each employee throughout the year while they were dealing with their medical issues. The Hays County Sheriff's Office family has always been willing to help each other, but we found it difficult to coordinate everyone. We are now able to pool our resources together, in one location, to help our family, with the Mitchum Assistance Foundation.`,
    description: `As president of the Mitchum Assistance Foundation, I track the organizational progress and evaluate the completion of established goals.`
  },
  {
    name: 'Jeffrey Spencer',
    title: 'Vice President',
    image: JeffreyImage,
    bio: `I have been employed with the Hays County Sheriff's office for 13 years, and have recently been promoted to the position of Corrections Lieutenant. I enjoy spending time with my family, going on motorcycle rides, traveling, and listening to music. I look forward to the growth of this foundation in assisting my fellow brothers and sisters of Hays County. `,
    description: `As Vice President of the Mitchum Assistance Foundation, my role is to oversee the meetings and responsibilities of the organization in the absence of the president.`
  },
  {
    name: 'Stacey Mitchum',
    title: 'Communications Manager & Secretary',
    image: StaceyImage,
    bio: `Greg is my husband and best friend. I am a nurse practitioner and have been in the medical field for 23 years - which helped Greg and me greatly during his battle. I also enjoy working out, working in my yard, and doing endless small projects at home. I am hopeful that this foundation will grow and that we will help our Hays County friends and family as they helped us during our struggle. `,
    description: `As the Communications Manager and Secretary, I ensure we are meeting our deadlines and managing our tasks at hand to meet our goals. I help to delegate these tasks and ensure we are working cohesively to help our community with precision and efficiency, to alleviate the stress of our Hays County family.`
  },
  {
    name: 'Mindi Duckworth',
    title: 'Treasurer',
    image: MindiImage,
    bio: `I've worked for the Hays County Sheriff's Office since 2013. I'm currently the Registrar for the Training Academy. I enjoy spending time with my family (husband Daniel, daughter Madison, and lab pup Ellie). I'm honored to serve on the board of the Mitchum Assistance Foundation and to help my Hays County family and friends in need. I’m very grateful to have worked with Greg. I remember being blown away when Greg told me that he and his wife Stacey make homemade pasta! Something I could never do!`,
    description: `As the Treasurer, I see my position as a steward in managing the Mitchum Assistance Foundation to ensure that the legal and financial requirements are handled under sound accounting principles that produce reliable financial information, ensure fiscal responsibility, and build public trust.`
  },
  {
    name: 'Kevin Dormer',
    title: 'Lead Software Engineer',
    image: KevinImage,
    bio: `Greg Mitchum is my father-in-law and has always been the image of strength, selflessness, and perseverance, but especially so during his battle with cancer. His thoughts and actions always centered around his family, his 'extended' family of HCSO, and his friends - something that inspires me daily. I am a professional software engineer and was honored to be able to use my skillsets alongside my wife, Angie, to create this page in his memory so that he could continue to help others as he so loved doing.`,
    description:`As the Lead Software Engineer, I am responsible for the development and maintenance of the Mitchum Assistance Foundation website. My team is working to ensure the website is easy to navigate and provides the information needed to help our Hays County family.`
  },
  {
    name: 'Angie Dormer',
    title: 'Marketing Director & Product Designer',
    image: AngieImage,
    bio: `Greg was my father. After losing him I couldn't wait for the opportunity to give back to other families in need. A huge thank you to the officers that spent their free time helping my dad and our family during his battle and I'm honored to be a part of this foundation. I designed the website and worked with my husband, Kevin, to program what you see here! In my free time, I love working out, being outside, and sweating!`,
    description:`As a product designer, I am responsible for the design of the Mitchum Assistance Foundation website. I work with the Lead Software Engineer to ensure the website is easy to navigate and provides the information needed to help our Hays County family. As the Marketing Director, I am responsible for the brand management of the Mitchum Assistance Foundation. Our brand is our promise to our community, and I work to ensure that our brand is consistent across all platforms and welcomes our community to our foundation.`
  }
]
