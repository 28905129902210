import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// Components
import Layout from "../Layout";
// Styles
import styles from "./styles.module.scss";

const ErrorPage = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <section className={styles.errorContainer}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <h1 className={styles.errorTitle}>
                Oops, we can't find that page!
              </h1>
              <p className={styles.errorText}>
                Please try again, or use the navigation above.
              </p>
            </Col>
          </section>
        </Row>
      </Container>
    </Layout>
  );
};

export default ErrorPage;
