import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import styles from "./styles.module.scss";

const ByLawPage = () => {
  return (
    <Layout>
      <div className={styles.legalPageBackground}>
        <h1>Bylaws</h1>
        <p>
          The Mitchum Assistance Foundation (Mitchum AF) was established to aid
          the Hays County Sheriff's Office with unforeseen emergency medical
          assistance. We will provide families in need with funds to assist with
          their medical expenses and bills. Currently, a member is required to
          be an employee of the Hays County Sheriff's Office to receive the
          funds and must have an unforeseen medical emergency that generates a
          need outside of the member's means to pay. Examples include, but are
          not limited to:
        </p>
        <ul>
          <li>
            Medical emergencies which generate medical debt that is large in sum
            to handle.{" "}
          </li>
          <li>
            Medical emergencies which cause an employee of the Hay's County
            Sheriff's Office to use FMLA to completion and are unable to meet
            their routine bills such as housing payments, utility payments, and
            medical payments directly due to the medical emergency.
          </li>
          <li>
            Unforeseen medical emergencies include, but are not limited to:
            cancer diagnosis, surgeries that leave the member out of work longer
            than their provided FMLA allows, or workers' compensation claims
            that are denied but interfere with the member's ability to maintain
            an income.{" "}
          </li>
        </ul>
        <p>
          The Board of the Mitchum Assistance Foundation will meet upon
          receiving a financial request to determine the validity and degree of
          emergency for each application. Members have the right to apply given
          any unforeseen medical emergency, and the board will determine if they
          qualify for assistance. Pending funds, the member has the right to
          apply as many times as they wish, and the Mitchum Assistance
          Foundation board will reply promptly with approval or declination with
          the reason. Information on members of the board can be found in the
          "Who we are" section on this website. The board comprises volunteers
          from the Hays County area who are set on aiding the community in need
          and will serve as long as they choose to. The board will be made up of
          at least 5 members, and should a member of the board step down
          replacements will be found voluntarily. The board will meet quarterly
          to discuss the next steps for future annual fundraisers and will
          communicate through email when an application is submitted for a
          speedy response. The fiscal year for this nonprofit shall be January
          1st - December 31st and an annual budget will be approved by the board
          to include expenses for fundraisers, funds to be given to applicants,
          and website maintenance. Amendments to these bylaws will be discussed
          at the last meeting of every year to ensure they are maintained and up
          to date with current practices.{" "}
        </p>
        <Link
          to={require("../../assets/maf_bylaws.pdf")}
          target="_blank"
          className={styles.legalLink}
        >
          Mitchum Assistance Foundation Bylaws (PDF)
        </Link>
      </div>
    </Layout>
  );
};

export default ByLawPage;
