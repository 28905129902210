import Layout from "../../components/Layout";
import styles from "./styles.module.scss";
const ConflictOfInterestPage = () => {
  return (
    <Layout>
      <div className={styles.legalPageBackground}>
        <h1>Conflict of Interest Policy</h1>
        <h2>Purpose</h2>
        <p>
          The purpose of this policy is to protect the interests of the Mitchum
          Assistance Foundation when it is contemplating entering into a
          transaction or arrangement that might benefit the private interest of
          an officer or director of the Mitchum Assistance Foundation or might
          result in a possible excess benefit transaction. This policy is
          intended to supplement but not replace any applicable state and
          federal laws governing conflict of interest applicable to nonprofit
          and charitable organizations.
        </p>
        <h2>Definitions</h2>
        <ul>
          <li>
            <b className={styles.boldSpace}>Interested Person: </b>Any director,
            officer, or key employee who has a direct or indirect financial
            interest.
          </li>
          <li>
            <b>Financial Interest: </b>A person has a financial interest if the
            person has, directly or indirectly, through business, investment, or
            family:
            <ul>
              <li>
                a. An ownership or investment interest in any entity with which
                the Mitchum Assistance Foundation has a transaction or
                arrangement
              </li>
              <li>
                b. A compensation arrangement with the Mitchum Assistance
                Foundation or with any entity or individual with which the
                Mitchum Assistance Foundation has a transaction or arrangement,
                or
              </li>
              <li>
                c. A potential ownership or investment interest in, or
                compensation arrangement with, any entity or individual with
                which the Mitchum Assistance Foundation is negotiating a
                transaction or arrangement.
              </li>
            </ul>
          </li>
        </ul>
        <h2>Procedures</h2>
        <ul>
          <li>
            Duty to Disclose: In connection with any actual or possible conflict
            of interest, an interested person must disclose the existence of the
            financial interest and be allowed to disclose all material facts to
            the directors and members of committees with governing board
            delegated powers considering the proposed transaction or
            arrangement.
          </li>
          <li>
            Determining Whether a Conflict of Interest Exists: After disclosure
            of the financial interest and all material facts, and after any
            discussion with the interested person, he/she shall leave the
            governing board or committee meeting while the determination of a
            conflict of interest is discussed and voted upon. The remaining
            board or committee members shall decide if a conflict of interest
            exists.
          </li>
          <li>
            Procedures for Addressing the Conflict of Interest:
            <ul>
              <li>
                a. An interested person may make a presentation at the governing
                board or committee meeting, but after the presentation, he/she
                shall leave the meeting during the discussion of, and the vote
                on, the transaction or arrangement involving the possible
                conflict of interest.
              </li>
              <li>
                b. The chairperson of the governing board or committee shall, if
                appropriate, appoint a disinterested person or committee to
                investigate alternatives to the proposed transaction or
                arrangement.
              </li>
              <li>
                c. After exercising due diligence, the governing board or
                committee shall determine whether the Mitchum Assistance
                Foundation can obtain with reasonable efforts a more
                advantageous transaction or arrangement from a person or entity
                that would not give rise to a conflict of interest.
              </li>
              <li>
                d. If a more advantageous transaction or arrangement is not
                reasonably possible under circumstances not producing a conflict
                of interest, the governing board or committee shall determine by
                a majority vote of the disinterested directors whether the
                transaction or arrangement is in the Mitchum Assistance
                Foundation's best interest, for its benefit, and whether it is
                fair and reasonable. In conformity with the above determination,
                it shall make its decision as to whether to enter into the
                transaction or arrangement.
              </li>
            </ul>
          </li>
          <li>
            Violations of the Conflict of Interest Policy:
            <ul>
              <li>
                a. If the governing board or committee has reasonable cause to
                believe a member has failed to disclose actual or possible
                conflicts of interest, it shall inform the member of the basis
                for such belief and allow the member to explain the alleged
                failure to disclose.
              </li>
              <li>
                b. If, after hearing the member's response and after making
                further investigation as warranted by the circumstances, the
                governing board or committee determines the member has failed to
                disclose an actual or possible conflict of interest, it shall
                take appropriate disciplinary and corrective action.
              </li>
            </ul>
          </li>
        </ul>
        <p style={{ paddingBottom: "5%" }}>
          Acknowledgment: All members of the board of directors, committees, and
          key employees shall annually review the legal section of this website
          to adhere to the expectations laid out.
        </p>
      </div>
    </Layout>
  );
};

export default ConflictOfInterestPage;
