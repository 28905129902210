// Components
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Footer/Footer";
import NavBar from "../Navbar";
import mafLogo from "../../assets/MitchumAFLogo.png";
// Styles
import styles from "./styles.module.scss";

const backgroundImageStyle = {
  backgroundImage: `url(${mafLogo})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundColor: "#0A0A0A",
  height: "100%",
  width: "100%",
};

const Layout = ({ children }) => {
  return (
    <Container fluid className={styles.container}>
      <Row>
        <div className={styles.navContainer}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <NavBar />
          </Col>
        </div>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div className={styles.contentContainer}>
            <div style={backgroundImageStyle}>{children}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className={styles.footerContainer}>
          <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Footer />
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default Layout;
