import React, { BaseSyntheticEvent, useEffect, useState } from "react";
// Component
import Layout from "../../components/Layout";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import Modal from "../../components/Modal";
// Helpers
import { send } from "emailjs-com";
// Styles
import styles from "./styles.module.scss";
import cn from "classnames";
import classNames from "classnames";

const initialFormState = {
  firstName: {
    value: "",
    error: false,
  },
  lastName: {
    value: "",
    error: false,
  },
  email: {
    value: "",
    error: false,
  },
  employeeNumber: {
    value: "",
    error: false,
  },
  hireDate: {
    value: "",
    error: false,
  },
  assistanceAmount: {
    value: "",
    error: false,
  },
  requestDetails: {
    value: "",
    error: false,
  },
};

const HelpPage = () => {
  const [form, setForm] = useState(initialFormState);
  const [showModal, setShowModal] = useState(false);
  const [emailServiceResponse, setEmailServiceResponse] = useState(null);

  const {
    firstName,
    lastName,
    email,
    employeeNumber,
    hireDate,
    assistanceAmount,
    requestDetails,
  } = form;

  useEffect(() => {
    if (emailServiceResponse === 200) {
      setShowModal(true);
      setForm(initialFormState);
      setEmailServiceResponse(null);
    }
  }, [emailServiceResponse]);

  const onFormSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    Object.keys(form).forEach((key) => {
      if (!form[key].value) {
        setForm((prevState) => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            error: true,
          },
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            error: false,
          },
        }));
      }
    });

    if (
      !firstName.value ||
      !lastName.value ||
      !email.value ||
      !employeeNumber.value ||
      !hireDate.value ||
      !assistanceAmount.value ||
      !requestDetails.value
    ) {
      return;
    } else {
      const reduceForm = Object.keys(form).reduce((acc, key) => {
        if (key === "email") {
          // @ts-ignore
          acc.reply_to = form[key].value;
          return acc;
        }
        acc[key] = form[key].value;
        return acc;
      }, {});

      send(
        "service_4tylv1v",
        "template_9yfky9u",
        reduceForm,
        "fV7SVupm-bOJMViXQ"
      )
        .then((response) => {
          setEmailServiceResponse(response.status);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log("FAILED...", err);
        });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Layout>
      <Container fluid>
        <section className={styles.formSection}>
          <Modal
            show={showModal}
            title="We have received your request!"
            bodyText="You will receive a confirmation email to the provided email address shortly."
            primaryButtonText="Close"
            onClick={handleModalClose}
          />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <h1 className={styles.formTitle}>Receive help now</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <p className={styles.formText}>
                To apply for financial assistance, fill out this quick form.
                Please be as detailed as possible. We will be discrete and quick
                to respond. Currently, you must be a Hays County Sherriff's
                office employee to qualify.
              </p>
            </Col>
          </Row>
          <Form onSubmit={onFormSubmit}>
            <Form.Group className="mb-3" controlId="requestAssistanceForm">
              <Row>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formText}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName.value}
                    className={classNames(
                      styles.formInput,
                      firstName.error && styles.error
                    )}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        firstName: {
                          ...form.firstName,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formText}>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName.value}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        lastName: {
                          ...form.lastName,
                          value: e.target.value,
                        },
                      })
                    }
                    className={classNames(
                      styles.formInput,
                      lastName.error && styles.error
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formText}>
                    Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={form.email.value}
                    name="email"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        email: {
                          ...form.employeeNumber,
                          value: e.target.value,
                        },
                      })
                    }
                    className={classNames(
                      styles.formInput,
                      employeeNumber.error && styles.error
                    )}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formText}>
                    Employee Number
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={form.employeeNumber.value}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        employeeNumber: {
                          ...form.employeeNumber,
                          value: e.target.value,
                        },
                      })
                    }
                    className={classNames(
                      styles.formInput,
                      employeeNumber.error && styles.error
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formText}>Hire Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={hireDate.value}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        hireDate: {
                          ...form.hireDate,
                          value: e.target.value,
                        },
                      })
                    }
                    className={classNames(
                      styles.formInput,
                      hireDate.error && styles.error
                    )}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formText}>
                    Assistance Amount
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={form.assistanceAmount.value}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        assistanceAmount: {
                          ...form.assistanceAmount,
                          value: e.target.value,
                        },
                      })
                    }
                    className={classNames(
                      styles.formInput,
                      assistanceAmount.error && styles.error
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Label className={styles.textAreaLabel}>
                    Take this space to let us know what you plan to use the
                    funds for. Be as descriptive as possible, as this will help
                    us decide whom to prioritize when disbursing the funds.
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={form.requestDetails.value}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        requestDetails: {
                          ...form.requestDetails,
                          value: e.target.value,
                        },
                      })
                    }
                    className={classNames(
                      styles.textAreaInput,
                      requestDetails.error && styles.error
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Button type="submit" className={cn(styles.submitFormButton)}>
                    Request assistance
                  </Button>
                </Col>
                <Col sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
              </Row>
            </Form.Group>
          </Form>
        </section>
      </Container>
    </Layout>
  );
};

export default HelpPage;
