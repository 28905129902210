import React from "react";
// Components
// Data
import { memberDetails } from "../../utils/memberDetails";
// Styles
import styles from "./styles.module.scss";
import cn from "classnames";
import Layout from "../../components/Layout";
import { Row, Col } from "react-bootstrap";

const WhoWeArePage = () => {
  return (
    <Layout>
      <section className={styles.container}>
        <h1 className={styles.formTitle}>Meet our team</h1>
        {memberDetails.map((member, index) => {
          if (index % 2 === 0) {
            return (
              <Row className={styles.memberRow} key={`${member.name}Row`}>
                <Col
                  className={styles.profilePicContainer}
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={3}
                >
                  <div className={styles.imageContainer}>
                    <img
                      src={member.image}
                      alt={member.name}
                      className={cn(styles.memberImage, styles.left)}
                      width={400}
                      height={400}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={9}>
                  <div className={styles.personDetails}>
                    <p
                      className={styles.nameTitle}
                    >{`${member.name} | ${member.title}`}</p>
                    <p className={styles.memberText}>{member.bio}</p>
                    <p className={styles.memberText}>{member.description}</p>
                  </div>
                </Col>
              </Row>
            );
          } else {
            return (
              <Row className={styles.oddRow} key={`${member.name}Row`}>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={9}>
                  <div className={styles.personDetails}>
                    <p
                      className={styles.nameTitle}
                    >{`${member.name} | ${member.title}`}</p>
                    <p className={styles.memberText}>{member.bio}</p>
                    <p className={styles.memberText}>{member.description}</p>
                  </div>
                </Col>
                <Col
                  className={styles.profilePicContainer}
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={3}
                >
                  <div className={styles.imageContainer}>
                    <img
                      src={member.image}
                      alt={member.name}
                      className={cn(styles.memberImage, styles.left)}
                      width={400}
                      height={400}
                    />
                  </div>
                </Col>
              </Row>
            );
          }
        })}
      </section>
    </Layout>
  );
};

export default WhoWeArePage;
