import React from "react";

import styles from "./styles.module.scss";

const ReceiptPage = () => {
  // const location = useLocation();
  // Pathname is the path of the page
  // Search is the query string
  // const { pathname, search } = location;
  return (
    <>
      <section className={styles.receiptPageContainer}>
        <h1 className={styles.receiptPageTitle}>Thank you!</h1>
        <p>
          Your contribution to the Mitchum Assistance Foundation will go towards
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit
          possimus similique quisquam, adipisci quas ullam quis animi dolores,
          illo exercitationem, esse nulla accusamus quo harum laborum qui
          reiciendis architecto laudantium.
        </p>
      </section>
    </>
  );
};

export default ReceiptPage;
