// Components
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
// Styles
import styles from "./styles.module.scss";

type ModalProps = {
  title: string;
  bodyText: string;
  primaryButtonText: string;
  show: boolean;
  onClick: () => void;
};

const MAFModal = (props: ModalProps) => {
  const { title, bodyText, primaryButtonText, show, onClick } = props;
  return (
    <Modal show={show} centered className={styles.modalContainer}>
      <Modal.Header>
        <Modal.Title>
          <h2>{title}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.modalText}>{bodyText}</p>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          variant="primary"
          className={styles.modalButton}
          onClick={onClick}
        >
          {primaryButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MAFModal;
