import React, { BaseSyntheticEvent, useEffect, useState } from "react";
// Components
import Layout from "../../components/Layout";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../../components/PaymentForm";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Utils
import { stripePromise } from "../../utils/stripe/stripe.utils";
// Styles
import styles from "./styles.module.scss";
import type { StripeElementsOptions } from "@stripe/stripe-js";
import classNames from "classnames";
import { Container } from "react-bootstrap";

// TODO: Set donations as numbers, not strings
// TODO: Reset state if buttons change value
// TODO: Format phone number
// TODO: Validate email
// TODO: Move primary /secondary styles to button, add as prop

type FormDetails = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  donation: number | null;
  howDidYouHearAboutUs: string;
};

const initialFormState: FormDetails = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  donation: null,
  howDidYouHearAboutUs: "",
};

const initialActiveButtonState = {
  25: false,
  50: false,
  100: false,
  custom: false,
};

const GiveHelpPage = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [customPaymentSelected, setCustomPaymentSelected] = useState(false);
  const [formDetails, setFormDetails] = useState(initialFormState);
  const [activeButton, setActiveButton] = useState(initialActiveButtonState);

  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: {
      disableAnimations: true,
      theme: "none",
      variables: {
        colorText: "#E9E9F9",
        colorTextPlaceholder: "black",
        colorTextSecondary: "black",
        colorBackgroundText: "black",
        colorPrimaryText: "black",
        spacingGridColumn: "24px",
      },
      rules: {
        ".Input": {
          color: "black",
        },
        ".CheckboxLabel": {
          color: "black",
        },
        ".CheckboxInput": {
          border: "1px solid black",
        },
      },
    },
  };

  const handleDonation = async (donation) => {
    setShowPaymentCard(true);
    const calculateOrderAmount = (donation) => {
      return Math.floor(donation * 100);
    };
    const response = await fetch("/.netlify/functions/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: calculateOrderAmount(donation),
      }),
    }).then(async (res) => await res.json());

    const { client_secret } = response.paymentIntent;
    setClientSecret(client_secret);
  };

  const handleButtonDonation = (donation) => {
    const intDonation = parseInt(donation);
    setFormDetails({ ...formDetails, donation: intDonation });
    setClientSecret("");

    switch (donation) {
      case 25:
        setActiveButton({
          25: true,
          50: false,
          100: false,
          custom: false,
        });
        return;
      case 50:
        setActiveButton({
          25: false,
          50: true,
          100: false,
          custom: false,
        });
        return;
      case 100:
        setActiveButton({
          25: false,
          50: false,
          100: true,
          custom: false,
        });
        return;
      default:
        return;
    }
  };

  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;
    if (name === "customDonation") {
      handleButtonDonation(parseInt(value));
    } else {
      setFormDetails({ ...formDetails, [name]: value });
    }
  };

  useEffect(() => {
    if (formDetails.donation) {
      handleDonation(formDetails.donation);
    }
  }, [formDetails.donation]);

  return (
    <Layout>
      <Container fluid>
        <section className={styles.formContainer}>
          <h1 className={styles.formTitle}>Help our cause</h1>
          <Form className={styles.formSection}>
            <p className={styles.formDescription}>
              Thank you for helping our fight - without donations, we wouldn't
              be able to help the Hays County Sherrif's Office employees with
              their unexpected financial emergencies.
            </p>
            <Form.Group className="mb-3" controlId="giveAssistanceForm">
              <h4 className={styles.subHeader}>Step 1 - Contact Info</h4>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formLabel}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formDetails.firstName}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formLabel}>
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formDetails.lastName}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formLabel}>
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={formDetails.phone}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Form.Label className={styles.formLabel}>
                    Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formDetails.email}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Label className={styles.formLabel}>
                    How did you hear about us?
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="howDidYouHearAboutUs"
                    value={formDetails.howDidYouHearAboutUs}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <h4 className={styles.subHeader}>Step 2 - Donation</h4>

              <Row>
                <Form.Label className={styles.formDescription}>
                  After you select the amount you wish to donate, step 3 will
                  populate and you can continue scrolling to finish this
                  section.
                </Form.Label>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <Form.Control
                    type="button"
                    value="$25.00"
                    onClick={() => handleButtonDonation(25)}
                    className={classNames(
                      activeButton[25] && styles.active,
                      styles.donationButton
                    )}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <Form.Control
                    type="button"
                    value="$50.00"
                    onClick={() => handleButtonDonation(50)}
                    className={classNames(
                      activeButton[50] && styles.active,
                      styles.donationButton
                    )}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <Form.Control
                    type="button"
                    value="$100.00"
                    onClick={() => handleButtonDonation(100)}
                    className={classNames(
                      activeButton[100] && styles.active,
                      styles.donationButton
                    )}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
                  <Form.Control
                    type="button"
                    value="Custom"
                    onClick={() => {
                      setActiveButton({
                        25: false,
                        50: false,
                        100: false,
                        custom: true,
                      });
                      setCustomPaymentSelected(true);
                    }}
                    className={classNames(
                      activeButton.custom && styles.active,
                      styles.donationButton
                    )}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  {customPaymentSelected && (
                    <Form.Control
                      type="number"
                      name="customDonation"
                      onBlur={(e) => handleButtonDonation(e.target.value)}
                      placeholder="Enter amount"
                      className={styles.donationButton}
                    />
                  )}
                </Col>
                <Col md={6} lg={6} xxl={6}></Col>
              </Row>
              <Row>
                {clientSecret && showPaymentCard && (
                  <Elements options={options} stripe={stripePromise}>
                    <PaymentForm onSubmit={handleDonation} form={formDetails} />
                  </Elements>
                )}
              </Row>
            </Form.Group>
          </Form>
        </section>
      </Container>
    </Layout>
  );
};

export default GiveHelpPage;
