import Layout from "../../components/Layout";
import styles from "./styles.module.scss";

const FinancialPage = () => {
  return (
    <Layout>
      <div className={styles.legalPageBackground}>
        <h1>Financial</h1>
        <p>
          We are beginning our first official fundraiser on April 29th and will
          be updating these financial records at the end of every fiscal year.
          Donations will be kept in a secure account to be disbursed upon
          receiving an application. Your donation will be taken and used as
          decided by the board of the Mitchum Assistance Foundation. The
          donation will be viewed as a part of the annual budget and will allow
          the board to aid with financial assistance, the annual fundraiser, and
          the maintenance of the website.{" "}
        </p>
      </div>
    </Layout>
  );
};

export default FinancialPage;
