import Layout from "../../components/Layout";
import styles from "./styles.module.scss";
const TermsOfUsePage = () => {
  return (
    <Layout>
      <div className={styles.legalPageBackground}>
        <h1>Terms of Use</h1>
        <h2>Acceptance of Terms</h2>
        <p>
          These terms of use ("Terms") apply to your use of the Mitchum
          Assistance Foundation's website (the "Website"). By using the Website,
          you agree to be bound by these Terms. If you do not agree to these
          Terms, you should not use the Website.
        </p>
        <h3>Use of the website</h3>
        <p>
          You may use the Website for lawful purposes only and in accordance
          with these Terms. You agree not to use the Website:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation
          </li>
          <li>
            To engage in any conduct that is abusive, harassing, threatening,
            harmful, or otherwise objectionable
          </li>
          <li>
            To transmit any unsolicited or unauthorized advertising, promotional
            materials, spam, or any other form of solicitation
          </li>
          <li>
            To interfere with or disrupt the Website or its servers or networks
          </li>
          <li>
            To attempt to gain unauthorized access to the Website, its servers
            or networks, or any other systems or networks connected to the
            Website
          </li>
        </ul>
        <h3>Intellectual Property Rights</h3>
        <p>
          The Website and its entire contents, features, and functionality,
          including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof, are owned by The Mitchum Assistance Foundation,
          its licensors, or other providers of such material and are protected
          by United States and international copyright, trademark, patent, trade
          secret, and other intellectual property or proprietary rights laws.
        </p>
        <p>
          You may use the Website for your personal, non-commercial use only.
          You may not copy, reproduce, distribute, modify, create derivative
          works of, publicly display, publicly perform, republish, download,
          store, or transmit any of the material on our Website, except as
          follows:
        </p>
        <ul>
          <li>
            Your computer may temporarily store copies of such materials in RAM
            incidental to your accessing and viewing those materials.
          </li>
          <li>
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>
          <li>
            You may print or download one copy of a reasonable number of pages
            of the Website for your own personal, non-commercial use and not for
            further reproduction, publication, or distribution.
          </li>
        </ul>
        <h3>Disclaimer of Warranties</h3>
        <p>
          The Website is provided "as is" and "as available" without any
          representations or warranties, express or implied. The Mitchum
          Assistance Foundation makes no representations or warranties in
          relation to the Website or the information and materials provided on
          the Website.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          To the fullest extent permitted by applicable law, in no event will
          the Mitchum Assistance Foundation, its affiliates, or their licensors,
          service providers, employees, agents, officers, or directors be liable
          for damages of any kind, under any legal theory, arising out of or in
          connection with your use, or inability to use, the Website, including
          any direct, indirect, special, incidental, consequential, or punitive
          damages, including but not limited to, personal injury, pain and
          suffering, emotional distress, loss of revenue, loss of profits, loss
          of business or anticipated savings, loss of use, loss of goodwill,
          loss of data, and whether caused by tort (including negligence),
          breach of contract, or otherwise, even if foreseeable.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless the Mitchum
          Assistance Foundation, its affiliates, and their respective directors,
          officers, employees, and agents from any claims, liabilities, damages,
          judgments, awards, losses, costs, expenses, or fees (including
          reasonable attorneys' fees) arising out of or relating to your
          violation of these Terms or your use of the Website.
        </p>
        <h3>Governing Law and Jurisdiction</h3>
        <p style={{ paddingBottom: "5%" }}>
          These Terms and your use of the Website shall be governed by and
          construed in accordance with the laws of the State.
        </p>
      </div>
    </Layout>
  );
};

export default TermsOfUsePage;
