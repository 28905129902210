import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// Components
import GregImg from "../../assets/greg.png";
import Layout from "../../components/Layout";
// Styles
import styles from "./styles.module.scss";

const DedicationPage = () => {
  return (
    <Layout>
      <Container fluid className={styles.dedicationSection}>
        <Row>
          <Col xs={1} sm={2} md={2} lg={2} xl={2} xxl={2}></Col>
          <Col xs={10} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <div className={styles.headshotContainer}>
              <img
                className={styles.gregHeadShot}
                src={GregImg}
                alt="Greg Mitchum's Headshot"
              />
            </div>
          </Col>
          <Col xs={1} sm={2} md={2} lg={2} xl={2} xxl={2}></Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <p className={styles.dedicationTitle}>
              <b>Gregory P. Mitchum | October 20th 1975 - December 13th 2021</b>
            </p>

            <p className={styles.dedicationText}>
              Greg was happiest behind the camera or in front of his computer
              editing - the excitement in his eyes when he was on the road with
              his wife and saw an abandoned building, a beautiful sunset to
              photograph, or packing his equipment to head out for a dark shoot
              at a local trail was contagious.
            </p>
            <p className={styles.dedicationText}>
              Greg cherished his friendships, specifically those he acquired at
              Hays County. So much so that one of his last requests was to take
              any leftover donations to his family and give it back to the
              county sheriff's office. He talked in depth with Officer Leigh
              Treat about this, and although we knew it was a tall order, we got
              to work quickly. The ones we asked to volunteer did not bat an eye
              and here we are, creating a fund to help other families in need!
            </p>
            <p className={styles.dedicationText}>
              He would always lift the spirits of everyone around him regardless
              of what was happening at work. He and Jeffrey Spencer formed a
              closer bond after Greg's diagnosis, the two were undergoing
              treatment for cancer. When speaking with Spencer, he mentioned,
              "Greg was always optimistic and we would continue to encourage
              each other during our treatment." In agreement with Jeffrey, Mindi
              Duckworth says, "Our conversations almost always ended in
              laughter, and I could see that he loved Stacey and his family very
              much."
            </p>
            <p className={styles.dedicationText}>
              If you knew Greg, you knew he had a dry and sarcastic personality,
              which proved to be beneficial in the office. Treat immediately
              noticed Greg's work ethic and commitment to teaching. He always
              arrived early and stayed late to make sure everything was done.
              Greg had a passion for teaching others and could teach anything
              from basic to expert courses. He had a talent for making it
              relative and interesting. Even after his diagnosis, Greg didn't
              change his work schedule - he insisted on helping and always
              remained positive. When I spoke with Treat, he said, "Greg
              motivated the academy employees from a position where it should
              have been the reverse." They took notice of this and decided they
              needed to do whatever Greg needed during his fight.
            </p>
            <p className={styles.dedicationText}>
              Greg always spoke about the future and how he could come back to
              work, offer free photo sessions for families in need when he
              healed, and always looked for a new way to give back. Greg was an
              inspiration from the minute you met him, and we are working to
              continue his legacy with this foundation. Thank you for being the
              man you were Greg. We miss you every day, and you will never be
              forgotten.
            </p>
            <h4 className={styles.signature}>"Let It Go."</h4>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default DedicationPage;
