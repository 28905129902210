import React, { useEffect, useState } from "react";
// Components
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/Layout";
import Modal from "../../components/Modal";
// Styles
import styles from "./styles.module.scss";

export default function Home() {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (location.search) {
      setSearch(location.search);
      setShowModal(true);
    }
  }, [location]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Layout>
      {search && (
        <Modal
          show={showModal}
          title="Thank you for your donation!"
          bodyText="Your donation has been received and will be used to help our Hays County officers in need."
          primaryButtonText="Close"
          onClick={handleModalClose}
        />
      )}
      <Container className={styles.container}>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Button className={styles.linkButton}>
              <Link to="/receiveHelp" className={styles.link}>
                Request discreet help
              </Link>
            </Button>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Button className={styles.linkButton}>
              <Link to="/giveHelp" className={styles.link}>
                Donate to our cause
              </Link>
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
