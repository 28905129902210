import Layout from "../../components/Layout";
import styles from "./styles.module.scss";

const PrivacyPage = () => {
  return (
    <Layout>
      <div className={styles.legalPageBackground}>
        <h1 style={{ alignSelf: "center" }}>Privacy Policy</h1>
        <p>
          The Mitchum Assistance Foundation respects your privacy and is
          committed to protecting your personal information. This privacy policy
          explains how we collect, use, and disclose your personal information
          and your rights in relation to that information.
        </p>
        <h2>Information We Collect</h2>
        <p>
          We may collect personal information from you when you interact with
          us, including when you:
        </p>
        <ul>
          <li>Donate to the Mitchum Assistance Foundation</li>
          <li>Apply for financial assistance</li>
          <li>Contact us with a question or concern</li>
        </ul>
        <p>
          The personal information we collect may include your name, email
          address, phone number, address, payment information, and other
          information you provide to us.
        </p>
        <h3>How We Use Your Information</h3>
        <p>
          We may use your personal information for the following purposes: We
          may use your personal information for the following purposes:
        </p>
        <ul>
          <li>To process your donations or applications</li>
          <li>To respond to your inquiries or concerns</li>
          <li>To improve our website, programs, and services</li>
          <li>To comply with legal obligations</li>
        </ul>
        <p>
          We may also use your personal information for other purposes with your
          consent or as otherwise permitted or required by law.
        </p>
        <h3>Sharing Your Information</h3>
        <p>
          We may share your personal information with third parties who provide
          services to us, such as payment processors. These third parties are
          prohibited from using your personal information for any other purpose.
        </p>
        <p>
          We may also disclose your personal information if we are required to
          do so by law, or if we believe that such disclosure is necessary to
          protect our rights or the safety of others.
        </p>
        <h3>Data Security</h3>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee its absolute security.
        </p>
        <h3>Your Rights</h3>
        <p>
          You have the right to access, correct, or delete the personal
          information that we hold, subject to certain exceptions provided by
          law. You may also have the right to object to or restrict our use of
          your personal information, and to request that we provide your
          personal information to you or another organization in a structured,
          commonly used, and machine-readable format.
        </p>
        <p>
          To exercise these rights, please contact us at{" "}
          <a href="mailto: mitchumaf@gmail.com">mitchumaf@gmail.com</a>.
        </p>
        <h3>Updates to this Privacy Policy</h3>
        <p style={{ paddingBottom: "5%" }}>
          We may update this privacy policy from time to time by posting a new
          version on our website. You should check this page occasionally to
          ensure you are familiar with any changes. Contact Us If you have any
          questions or concerns about this privacy policy or our use of your
          personal information, or anything regarding the Mitchum Assistance
          Foundation please contact us by phone at 512-784-6858 or email our
          team at <a href="mailto: mitchumaf@gmail.com">mitchumaf@gmail.com</a>.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPage;
