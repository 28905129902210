import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import styles from "./styles.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";

export const getBasePath = (url) => url.replace(/\/[^/]+$/, "");

const PaymentForm = ({ onSubmit, form }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message] = useState(null);
  const [isLoading] = useState(false);

  const paymentHandler = async (e) => {
    e.preventDefault();
    // Do not proceed unless Stripe and Elements are loaded
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${getBasePath(window.location.href)}`,
        receipt_email: form.email,
      },
    });
    if (error) {
      alert(error.message);
    }
  };

  // if (paymentResult.error) {
  //   console.log(paymentResult.error.message);
  // } else {
  //   if (paymentResult.paymentIntent.status === "succeeded") {
  //     console.log("Payment succeeded!");
  //   }
  // }

  // confirmParams: {
  //   // Make sure to change this to your payment completion page
  //   return_url: "http://localhost:3000/receipt",
  // },

  // setIsLoading(true);
  // const currentUrl = window.location.href;
  // const getBasePath = (url) => {
  //   return url.replace(/\/[^/]+$/, "");
  // };

  // This point will only be reached if there is an immediate error when
  // confirming the payment. Otherwise, your customer will be redirected to
  // your `return_url`. For some payment methods like iDEAL, your customer will
  // be redirected to an intermediate site first to authorize the payment, then
  // redirected to the `return_url`.
  // if (error.type === "card_error" || error.type === "validation_error") {
  //   setMessage(error.message);
  // } else {
  //   setMessage("An unexpected error occurred.");
  // }

  // setIsLoading(false);

  return (
    <Container fluid>
      <form onSubmit={onSubmit} name="paymentForm">
        <h4 className={styles.subHeader}>Step 3 - Card Details</h4>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <PaymentElement />
          </Col>
          <Col sm={3} md={3} lg={3} xl={3} xxl={3}></Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Button
              disabled={isLoading || !stripe || !elements}
              id="submit"
              className={styles.paymentButton}
              onClick={paymentHandler}
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Submit Donation"
                )}
              </span>
            </Button>
          </Col>
          <Col sm={3} md={3} lg={3} xl={3} xxl={3}></Col>
        </Row>
        {message && <p className={styles.message}>{message}</p>}
      </form>
    </Container>
  );
};

export default PaymentForm;
