// Component
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
// Styles
import styles from "./styles.module.scss";

type LegalPageProps = {
  docs: { url: string; linkText: string }[];
};

const LegalPage = (props: LegalPageProps) => {
  const { docs } = props;
  return (
    <Layout>
      <Container fluid className={styles.legalContainer}>
        <h1 style={{ textAlign: "center" }}>Legal Information</h1>
        <h2 className={styles.subHeader}>Details</h2>
        <div className={styles.infoLinkContainer}>
          <Link to="/legal/bylaws" className={styles.legalLink}>
            Bylaws
          </Link>
          <Link to="/legal/financial" className={styles.legalLink}>
            Financial
          </Link>
          <Link to="/legal/coi" className={styles.legalLink}>
            Conflict of Interest
          </Link>
          <Link to="/legal/termsOfUse" className={styles.legalLink}>
            Terms of Use
          </Link>
          <Link to="/legal/privacy" className={styles.legalLink}>
            Privacy Policy
          </Link>
        </div>
        <h2 className={styles.subHeader}>Documents</h2>
        <ul className={styles.legalLinkList}>
          {docs.map((doc, index) => (
            <li className={styles.legalLink} key={index}>
              <a href={doc.url} target="_blank">
                {doc.linkText}
              </a>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  );
};

export default LegalPage;
