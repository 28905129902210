import { Container } from "react-bootstrap";
import Layout from "../../components/Layout";
import styles from "./styles.module.scss";

const ContactUsPage = () => {
  return (
    <Layout>
      <Container fluid className={styles.contactUsContainer}>
        <h1 style={{ textAlign: "center" }}>Contact Us</h1>
        <div className={styles.contacts}>
          <h4>Email: </h4>
          <span>
            <a href="mailto:mitchumaf@gmail.com">mitchumaf@gmail.com</a>
          </span>
          <h4>Phone: </h4>
          <span>+1 (512) 942-7704</span>
          <h4>
            For the most up-to-date information, follow us on Facebook via the
            link below:
          </h4>
          <span>
            <a href="https://www.facebook.com/mitchumaf">
              Mitchum Assistance Foundation
            </a>
          </span>
        </div>
      </Container>
    </Layout>
  );
};

export default ContactUsPage;
