import { Route, Routes } from "react-router-dom";
// Components
import Home from "./routes/home";
import HelpPage from "./routes/receiveHelp";
import GiveHelpPage from "./routes/giveHelp";
import WhoWeArePage from "./routes/whoWeAre";
import DedicationPage from "./routes/dedication";
import ReceiptPage from "./routes/receipt";
import LegalPage from "./routes/legal";
import ErrorPage from "./components/ErrorPage";
import ByLawPage from "./routes/legal/bylawsPage";
import FinancialPage from "./routes/legal/financialPage";
import ConflictOfInterestPage from "./routes/legal/conflictOfInterestPage";
import TermsOfUsePage from "./routes/legal/termsOfUsePage";
import PrivacyPage from "./routes/legal/privacyPage";
import ContactUsPage from "./routes/contact";

// Docs
const legalDocs = [
  {
    url: require("./assets/approved_cert_of_formation.pdf"),
    linkText: "Certification of Formation (PDF)",
  },
  {
    url: require("./assets/texas_applications_exemption.pdf"),
    linkText: "Texas Application for Exemption (PDF)",
  },
];

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/receiveHelp" element={<HelpPage />} />
      <Route path="/giveHelp" element={<GiveHelpPage />} />
      <Route path="/receipt" element={<ReceiptPage />} />
      <Route path="/whoWeAre" element={<WhoWeArePage />} />
      <Route path="/dedication" element={<DedicationPage />} />
      <Route path="/legal" element={<LegalPage docs={legalDocs} />} />
      <Route path="/legal/bylaws" element={<ByLawPage />} />
      <Route path="/legal/financial" element={<FinancialPage />} />
      <Route path="/legal/coi" element={<ConflictOfInterestPage />} />
      <Route path="/legal/termsOfUse" element={<TermsOfUsePage />} />
      <Route path="/legal/privacy" element={<PrivacyPage />} />
      <Route path="/contact" element={<ContactUsPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
